module directives {
    export module applicationcore {

        interface IDashboardScope extends ng.IScope {
            gtsDirective : string
        }

        export class dashboardDirective implements ng.IDirective {
            template = ``;
            scope = {
                gtsDirective : "="
            }

            constructor(
                private $compile : ng.ICompileService

            ) {
                   
            }

            link = ($scope: IDashboardScope, $element: ng.IAugmentedJQuery, $state) => {
                var generatedTemplate = '<' + $scope.gtsDirective + '></'+$scope.gtsDirective + '>';
                $element.append(this.$compile(generatedTemplate)($scope));
            }


            static factory(): ng.IDirectiveFactory {
                const directive = ($compile) => new dashboardDirective($compile);
                directive.$inject = ["$compile"];

                return directive;
            }
        }

        angular.module("app").directive("gtsDashboard", dashboardDirective.factory());
    }
}